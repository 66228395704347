<template>
  <Protocols image="404" title="Parece que você está perdido" />
</template>

<script>
export default {
  components: {
    Protocols: () => import('@/components/protocols')
  }
};
</script>
